import { render, staticRenderFns } from "./ShapesControl.vue?vue&type=template&id=20330990&scoped=true&"
import script from "./ShapesControl.vue?vue&type=script&lang=js&"
export * from "./ShapesControl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20330990",
  null
  
)

export default component.exports